const invoiceElements = {
    'fr': [
        {name: 'Code de facture', value: '{code}'},
        {name: 'Date de publication', value: '{date_de_publication}'},
        {name: 'Délai de paiement', value: '{date_echeance}'},
        {name: 'Catégorie d\'opération', value: '{categorie_operation}'},
        {name: 'Libellé', value: '{libelle}'},
        {name: 'Commentaire', value: '{commentaire}'},
        {name: 'Mode de paiement', value: '{mode_de_paiement}'},
        {name: 'Mode de facturation', value: '{mode_de_facturation}'},
        {name: 'Montant HT', value: '{montant_ht}'},
        {name: 'Montant TVA', value: '{montant_vat}'},
        {name: 'Montant TTC', value: '{montant_ttc}'},
        {name: 'Tableau TVA Vertical', value: '{tableau_tva_vertical}'},
        {name: 'Tableau TVA Horizontal', value: '{tableau_tva_horizontal}'}
    ],
    'en': [
        {name: 'Invoice code', value: '{code}'},
        {name: 'Publication date', value: '{publication_date}'},
        {name: 'Due date', value: '{due_date}'},
        {name: 'Invoice category', value: '{invoice_category}'},
        {name: 'Label', value: '{label}'},
        {name: 'Comment', value: '{comment}'},
        {name: 'Way of payment', value: '{way_of_payment}'},
        {name: 'Invoicing way', value: '{invoicing_way}'},
        {name: 'Amount HT', value: '{amount_ht}'},
        {name: 'Amount VAT', value: '{amount_vat}'},
        {name: 'Amount TTC', value: '{amount_ttc}'},
        {name: 'Vertical VAT Table', value: '{vertical_vat_table}'},
        {name: 'Horizontal VAT Table', value: '{horizontal_vat_table}'}
    ]
};

export default invoiceElements;