export const getCurrentLocale = () => {
  return document.body.getAttribute('data-locale');
};

// Configuration générale de la barre d'outils pour tous les éditeurs
export const toolbarOptions = [
    "undo",
    "redo",
    "|",
    "bold",
    "italic",
    "underline",
    "eraser",
    "|",
    "font",
    "fontsize",
    "lineHeight",
    "|",
    "paragraph",
    "|",
    "brush",
    "|",
    "align",
    "indent",
    "outdent",
    "|",
    "ul",
    "ol",
    "|",
    "table",
    "hr",
    "|",
    "symbols",
    "superscript",
    "subscript",
    "|",
    "source",
    "|"
];

// Fonction pour obtenir le dropdown des éléments dynamiques
export const getDynamicElementDropdown = (name, tooltip, elements) => {
    return {
      name: name,
      tooltip: tooltip,
      list: elements.map(item => item.name),
      childTemplate: (editor, key, value) => {
        return `<span>${value}</span>`;
      },
      exec(editor, _, {control}) {
        let value = control.args && control.args[0];
        const elementValue = elements.find(item => item.name === value).value;
        editor.selection.insertHTML(elementValue);
        editor.setEditorValue();
        return false;
      }
    };
  };
  
