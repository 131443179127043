const servicesElements = {
    'fr': [
        {name: 'Liste des services facturés', value: '{invoiced_services} <br><br> {/invoiced_services}'},
        {name: 'Description du service', value: '{description}'},
        {name: 'Quantité', value: '{quantite}'},
        {name: 'Prix unitaire', value: '{prix_unitaire}'},
        {name: 'Taux TVA', value: '{taux_tva}'},
        {name: 'Rabais', value:"{rabais"},
        {name: 'Montant total HT', value: '{montant_total_ht}'},
    ],
    'en': [
        {name: 'Invoiced services list', value: '{invoiced_services} <br><br> {/invoiced_services}'},
        {name: 'Service description', value: '{description}'},
        {name: 'Quantity', value: '{quantity}'},
        {name: 'Unit price', value: '{unit_price}'},
        {name: 'Rate VAT', value: '{rate_vat}'},
        {name: 'Discount', value:"{discount"},
        {name: 'Total amount excluding tax', value: '{total_amount_excl_tax}'},
    ]
};

export default servicesElements;