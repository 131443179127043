import 'core-js/stable'
import 'regenerator-runtime/runtime'

// import your styles
import '../stylesheets/application.css'
import '../stylesheets/application.scss' 

//= require rails-ujs
//= require activestorage
//= require turbolinks
//= require_tree .
//= require jquery

// import any required JavaScript
// import 'bootstrap'


import $ from 'jquery'
import Swal from 'sweetalert2'
import 'cocoon-js';

import 'materialize-css/dist/css/materialize.min.css';
import 'materialize-css/dist/js/materialize.min.js';  

import 'jquery-ui-dist/jquery-ui';
import 'jquery-ui-dist/jquery-ui.css';

import 'jodit';
import 'jodit/build/jodit.min.css';

import { Jodit } from 'jodit';

import Chart from 'chart.js/auto';

import { toolbarOptions, getDynamicElementDropdown, getCurrentLocale } from './joditConfig.js';
import companyElements from './jodit_elements/companyElements';
import billingElements from './jodit_elements/billingElements';
import customerElements from './jodit_elements/customerElements';
import bankAccountElements from './jodit_elements/bankAccountElements';
import invoiceElements from './jodit_elements/invoiceElements';
import servicesElements from './jodit_elements/servicesElements';

const imagesContext = require.context('../images/jquery-ui', true);
const imagePath = (name) => imagesContext(name, true);

function createJodit(editorId, hiddenFieldId) {

  const currentLocale = getCurrentLocale();
  const companyDropdown = getDynamicElementDropdown(
    currentLocale == "fr" ? "Société" : "Company",
    currentLocale == "fr" ? "Paramètres de la société" : "Company settings",
    companyElements[currentLocale]
  );
  const billingDropdown = getDynamicElementDropdown(
    currentLocale == "fr" ? "Facturation" : "Billing",
    currentLocale == "fr" ? "Paramètres de facturation" : "Billing settings",
    billingElements[currentLocale]
  );
  const customerDropdown = getDynamicElementDropdown(
    currentLocale == "fr" ? "Client" : "Customer",
    currentLocale == "fr" ? "Paramètres du client" : "Customer settings",
    customerElements[currentLocale]
  );
  const bankAccountDropdown = getDynamicElementDropdown(
    currentLocale == "fr" ? "Banque" : "Bank account", 
    currentLocale == "fr" ? "Paramètres du compte bancaire rattaché au client" : "Customer's bank account settings", 
    bankAccountElements[currentLocale]
  );
  const invoiceDropdown = getDynamicElementDropdown(
    currentLocale == "fr" ? "Facture" : "Invoice", 
    currentLocale == "fr" ? "Paramètres de la facture" : "Invoice settings", 
    invoiceElements[currentLocale]
  );
  const servicesDropdown = getDynamicElementDropdown(
    currentLocale == "fr" ? "Services" : "Services", 
    currentLocale == "fr" ? "Paramètres des services facturés" : "Invoiced services settings", 
    servicesElements[currentLocale]
  );

  const logoPath = document.getElementById('my_logo_path').dataset.logoPath;

  const editor = Jodit.make(editorId, {
    buttons: [
      ...toolbarOptions,
      {
        name: 'Logo',
        tooltip: 'Insérer le logo',
        exec: function (editor) {
          const img = editor.createInside.element('img', {
              src: logoPath
          });
          editor.selection.insertNode(img);
        }
      },
      "|",
      companyDropdown,
      "|",
      billingDropdown,
      "|",
      customerDropdown,
      "|",
      bankAccountDropdown,
      "|",
      invoiceDropdown,
      "|",
      servicesDropdown,
      "|"
    ]
  });

  const hiddenField = document.getElementById(hiddenFieldId);

  editor.events.on('change', function () {
    if(editorId == '#service-table-editor'){
      const tables = editor.editor.querySelectorAll('table');

      tables.forEach((table) => {
        // Si un élément <thead> n'est pas présent, créez-en un
        if (!table.querySelector('thead')) {
          const thead = document.createElement('thead');
          const firstRow = table.querySelector('tr');

          if (firstRow) {
            // Déplacez la première ligne du tableau vers le nouvel élément <thead>
            firstRow.parentNode.removeChild(firstRow);
            thead.appendChild(firstRow);
            table.insertBefore(thead, table.firstChild);
          }
        }
      });
    }
    
    hiddenField.value = editor.value;
  });

  if (hiddenField.value) {
    editor.value = hiddenField.value;
  }

  return editor;
}

function confirmSwal(message, url, method, callback) {
    Swal.fire({
      title: message,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Oui',
      cancelButtonText: 'Annuler'
    }).then((result) => {
      if (result.isConfirmed) {
        callback(url, method)
      }
    })
}

function getCookie(name) {
  const value = "; " + document.cookie;
  const parts = value.split("; " + name + "=");
  if (parts.length === 2) return parts.pop().split(";").shift();
}

function sendAjaxRequest(url, method) {
    const csrfToken = document.querySelector('meta[name="csrf-token"]').content;
    const headers = new Headers();
    headers.append("X-CSRF-Token", csrfToken);
    headers.append("Content-Type", "application/json");
  
    fetch(url, {
      method: method,
      headers: headers,
      credentials: "same-origin",
    })
      .then((response) => {
        if (response.ok) {
            response.json().then((data) => {
              sessionStorage.setItem("notificationMessage", data.notice);
              sessionStorage.setItem("notificationType", data.notice_type || 'success');
              location.reload();
            });
          } else {
          Swal.fire("Erreur", "Une erreur s'est produite lors de la suppression.", "error");
        }
      })
      .catch((error) => {
        Swal.fire("Erreur", "Une erreur s'est produite lors de la suppression.", "error");
      });
}

function updateTotalAmount() {
  var totalAmount = 0;
  $(".invoiced_service_provided").each(function() {
    var quantity = parseFloat($(this).find("[name$='[quantity]']").val()) || 0;
    var unitPrice = parseFloat($(this).find("[name$='[unit_price]']").val()) || 0;
    var discount = parseFloat($(this).find("[name$='[discount]']").val()) || 0;
    var rateVat = parseFloat($(this).find("[name$='[rate_vat]']").val()) || 0;
    var amount = (quantity * unitPrice) * (1 - discount / 100) * (1 + rateVat / 100);
    totalAmount += amount;
  });
  $("#total_amount").text(totalAmount.toFixed(2));
}

function previewInvoice(event) {
  event.preventDefault();

  const form = document.querySelector("form");
  const preview_button = document.getElementById("invoice_preview");

  const invoice_customer_id = form.querySelector("[name='invoice[customer_id]']").value;
  const invoice_label = form.querySelector("[name='invoice[label]']").value;
  const invoiced_services = form.querySelectorAll(".invoiced_service_provided").length > 0;

  let errors = [];

  if (!invoice_customer_id) {
    errors.push("Le champ client est obligatoire");
  }

  if(invoice_label.length == 0){
    errors.push("Le champ label est obligatoire");
  }

  if (!invoiced_services) {
    errors.push("Au moins un service facturé est requis");
  }

  if (errors.length > 0) {
    // Affiche les erreurs dans ton div .error_explanation
    const errorDiv = document.querySelector(".error_explanation");
    errorDiv.innerHTML = `<h2>${errors.length} erreur(s) détectée(s) :</h2><ul>${errors.map(e => `<li>${e}</li>`).join("")}</ul>`;
    return;
  }

  $(preview_button).find("i").addClass("none");
  $(preview_button).find(".preloader-wrapper").removeClass("none");

  let currentPath = window.location.pathname;
  let formData = new FormData(form);
  formData.delete("_method");
  formData.delete("authenticity_token");
  formData.append('current_path', currentPath);


  const csrfToken = document.querySelector('meta[name="csrf-token"]').content;
  const headers = new Headers();
  headers.append("X-CSRF-Token", csrfToken);
  
  fetch("/invoices/preview", {
    method: 'POST',
    headers: headers,
    body: formData
  })
  .then( response => response.blob())
  .then(blob => {
    $(preview_button).find(".preloader-wrapper").addClass("none");
    $(preview_button).find("i").removeClass("none");
    const url = window.URL.createObjectURL(blob);
    window.open(url, '_blank');
  });
}

function previewInvoiceTemplate(event) {
  event.preventDefault();

  const preview_button = document.getElementById("invoice_template_preview");
  $(preview_button).find("i").addClass("none");
  $(preview_button).find(".preloader-wrapper").removeClass("none");

  const form = document.getElementById("invoice_template_form");
  let formData = new FormData(form);
  formData.delete("_method");
  formData.delete("authenticity_token");

  const csrfToken = document.querySelector('meta[name="csrf-token"]').content;
  const headers = new Headers();
  headers.append("X-CSRF-Token", csrfToken);
  
  fetch("/invoice_templates/preview", {
    method: 'POST',
    headers: headers,
    body: formData
  }) 
  .then(response => response.blob())
  .then(blob => {
    $(preview_button).find(".preloader-wrapper").addClass("none");
    $(preview_button).find("i").removeClass("none");
    const url = window.URL.createObjectURL(blob);
    window.open(url, '_blank');
  });
}

function initTableHeight() {
  const tableau = $(".table_box");
  const espaceEnHaut = tableau.offset().top;
  const hauteurEcran = $(window).height();
  const hauteurMax = hauteurEcran - espaceEnHaut - 10;

  tableau.css('max-height', hauteurMax + 'px');
  tableau.css('overflow-y', 'auto');
}

function hideBorderPrevLine() {
  $('.invoice_association').each(function(){
    if($(this).is(":hidden")){
      $(this).prev().css('border-bottom', '1px solid rgba(0,0,0,0.12)');
    } else {
      $(this).prev().css('border-bottom', 'none');
    }
  });
}

function init() {
  const sideNavElems = document.querySelectorAll(".sidenav");
  const sideNavInstances = M.Sidenav.init(sideNavElems);

  var elems = document.querySelectorAll('.tabs');
  var instances = M.Tabs.init(elems);
  
  var dropdown_options = {
    constrainWidth: false,
    hover: true,
    alignment: 'right'
  }
  var dropdownElems  = document.querySelectorAll(".dropdown-trigger");
  var dropdownInstances  = M.Dropdown.init(dropdownElems , dropdown_options);

  var select_options = {
    dropdownOptions: {
      constrainWidth: true,
      hover: false
    }
  }
  var selectElems  = document.querySelectorAll('select');
  var selectInstances  = M.FormSelect.init(selectElems , select_options);


  var elems = document.querySelectorAll('.tooltipped');
  var instances = M.Tooltip.init(elems);

  $('.tooltipped').tooltip();

  var toggle = document.querySelector(".invoice-address-toggle");
  var fields = document.querySelector(".invoice-address-fields");

  if(toggle != null){
      toggle.addEventListener("change", function() {
        fields.style.display = this.checked ? "block" : "none";
      });

      if (toggle.checked) {
        fields.style.display = "block";
      }
  }

  const notices = document.querySelectorAll('[data-notice]');
  notices.forEach((notice) => {
    const type = notice.dataset.noticeType;
    let classes = 'rounded';
    let icon = '';
    console.log(type)
    if (type === 'success') {
      classes += ' background-green';
      icon = '<i class="material-icons margin-right-15">check_circle</i>';
    } else if (type === 'warning') {
      classes += ' background-orange';
      icon = '<i class="material-icons margin-right-15">warning</i>';
    } else if (type === 'error') {
      classes += ' background-red';
      icon = '<i class="material-icons margin-right-15">warning</i>';
    }
  
    M.toast({ html: icon + " " + notice.dataset.notice, classes: classes });
  });

  const notificationMessage = sessionStorage.getItem("notificationMessage");
  const notificationType = sessionStorage.getItem("notificationType");
  if (notificationMessage) {
    let classes = 'rounded';
    let icon = '';

    if (notificationType === 'success') {
      classes += ' background-green';
      icon = '<i class="material-icons margin-right-15">check_circle</i>';
    } else if (notificationType === 'warning') {
      classes += ' background-orange';
      icon = '<i class="material-icons margin-right-15">warning</i>';
    } else if (notificationType === 'error') {
      classes += ' background-red';
      icon = '<i class="material-icons margin-right-15">warning</i>';
    }
    M.toast({ html: icon + " " + notificationMessage, classes: classes });
    sessionStorage.removeItem("notificationMessage");
    sessionStorage.removeItem("notificationType");
  }

  $('a[data-confirm], button[data-confirm]').click(function(e) {
      e.preventDefault()
      if($(this).parent().hasClass("button_to")){
          var url = $(this).parent().attr('action');
          var method = $(this).parent().find("input[name='_method']").val();
      }
      else{
          var url = $(this).attr('href');
          var method = $(this).attr('method');
      }
      var message = $(this).data('confirm');

      confirmSwal(message, url, method, sendAjaxRequest);
  });

  const defaultIcons = document.querySelectorAll(".default-icon");

  defaultIcons.forEach((icon) => {
      icon.addEventListener("click", (event) => {
      const url = event.target.dataset.url;
      if (url) {
          fetch(url, {
                  method: "PUT",
                  headers: {
                      "X-CSRF-Token": document.querySelector('meta[name="csrf-token"]').content,
                      "Content-Type": "application/json",
                  },
                  credentials: "same-origin",
              })
              .then((response) => {
              if (response.ok) {
                  return response.json();
              } else {
                  throw new Error("Une erreur s'est produite lors de la mise à jour du compte par défaut.");
              }
              })
              .then((data) => {
                  sessionStorage.setItem("notificationMessage", data.notice);
                  location.reload();
              })
              .catch((error) => {
                  M.toast({ html: error.message, classes: "red" });
              });
          }
      });
  }); 

  $(document).on('cocoon:after-insert', function() {
    var elems = document.querySelectorAll('select');
    var instances = M.FormSelect.init(elems);
      updateTotalAmount();
    });
  
  $(document).on('cocoon:after-remove',function() {
      updateTotalAmount();
  });
  
  $(document).on("change", ".invoiced_service_provided input, .invoiced_service_provided select", function() {
    updateTotalAmount();
  });
  
  updateTotalAmount(); 

  $('input').each(function() {
      if ($(this).val()) {
        $(this).siblings('label').addClass('active');
      }
  });

  $('.customer-autocomplete').autocomplete({
      source: function (request, response) {
          // console.log("CSRF Token: ", $('meta[name="csrf-token"]').attr('content'));

          $.ajax({
              url: '/customers/autocomplete',
              dataType: 'json',
              data: {term: request.term},
              beforeSend: function(xhr) {xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr("content"))},
              success: function (data) {
                  response($.map(data, function (customer) {
                      return {label: customer.company_name, value: customer.company_name, id: customer.id, data : customer};
                  }));
              }
          });
      },
      minLength: 1,
      select: function (event, ui) {
          $(this).val(ui.item.value);
          $(this).siblings('input[type=hidden]').val(ui.item.id);
          // Récupérez ici les autres attributs de la société sélectionnée, si nécessaire
          return false;
      }
  });

  if($("#header-left-editor:visible").length > 0){
      const headerLeftEditor = createJodit('#header-left-editor', 'header-left-html');
      const headerRightEditor = createJodit('#header-right-editor', 'header-right-html');
      const headerBottomEditor = createJodit('#header-bottom-editor', 'header-bottom-html');
      const serviceTableEditor = createJodit('#service-table-editor', 'service-table-html');
      const serviceTableBottomEditor = createJodit('#service-table-bottom-editor', 'service-table-bottom-html');
      const footerEditor = createJodit('#footer-editor', 'footer-html');
  }

  $("#payment_info_tab").on("click", function() {
    $('#customer_use_spe_billing_footer')[0].checked ? $('#spe_billing_footer_textarea')[0].css("display", "block") : $('#spe_billing_footer_textarea')[0].css("display", "none");
  })

  $("#invoice_preview").on("click", function(e) {
    previewInvoice(e);
  })

  $("#invoice_template_preview").on("click", function(e) {
    previewInvoiceTemplate(e);
  })

  $('#customer_use_spe_billing_footer').on('change', function() {
    var speBillingField = $('#spe_billing_footer_textarea')[0];

    if (this.checked) {
      speBillingField.style.display = 'block';
    } else {
      speBillingField.style.display = 'none';
    }
  });

  $('#billing_setting_upload_logo').on('change', function(event) {
    const input = event.target;
    
    if (input.files && input.files[0]) {
      var reader = new FileReader();
      
      reader.onload = function(e) {
        // Cache l'image actuelle
        $(".materialboxed").hide();

        // Crée une nouvelle image et l'affiche
        const newImage = $('<img class="materialboxed" height="60" src="' + e.target.result + '">');
        newImage.insertAfter(".file-field");
      }
      
      reader.readAsDataURL(input.files[0]);
    }
  });

  $('#customer_is_paying_after_the_billing').on('change', function() {
    if(this.checked) {
      $('#customer_is_paying_after_the_end_of_month').prop('checked', false);
    }
  });
  
  $('#customer_is_paying_after_the_end_of_month').on('change', function() {
    if(this.checked) {
      $('#customer_is_paying_after_the_billing').prop('checked', false);
    }
  });

  $('#billing_setting_is_vat_payed_on_recepts').on('change', function() {
    if(this.checked) {
      $('#billing_setting_is_vat_payed_on_debits').prop('checked', false);
    }
  });
  
  $('#billing_setting_is_vat_payed_on_debits').on('change', function() {
    if(this.checked) {
      $('#billing_setting_is_vat_payed_on_recepts').prop('checked', false);
    }
  });

  $('#billing_setting_is_payment_as_from_month_end').on('change', function() {
    if(this.checked) {
      $('#billing_setting_is_payment_as_from_date_billing').prop('checked', false);
    }
  });
  
  $('#billing_setting_is_payment_as_from_date_billing').on('change', function() {
    if(this.checked) {
      $('#billing_setting_is_payment_as_from_month_end').prop('checked', false);
    }
  });

  $('#complete').on('change', function() {
    var dateFromField = $('#date-from-field')[0];
    var dateToField = $('#date-to-field')[0];

    if(this.checked) {
      $('#export-date-checkbox').prop('checked', false);
      dateFromField.style.display = 'none';
      dateToField.style.display = 'none';
    }
  });

  $('#export-date-checkbox').on('change', function() {
    var dateFromField = $('#date-from-field')[0];
    var dateToField = $('#date-to-field')[0];

    if (this.checked) {
      $('#complete').prop('checked', false);
      dateFromField.style.display = 'block';
      dateToField.style.display = 'block';
    } else {
      dateFromField.style.display = 'none';
      dateToField.style.display = 'none';
    }
  });

  if($("#invoicesChart:visible").length > 0){
    let invoicesChart = document.getElementById('invoicesChart');
    let labelsInvoices = JSON.parse(invoicesChart.dataset.labels);
    let datasetInvoices = JSON.parse(invoicesChart.dataset.dataset);

    new Chart(invoicesChart, {
      type: 'line',
      data: {
        labels: labelsInvoices,
        datasets: [{
          label: 'Invoices',
          data: datasetInvoices,
          backgroundColor: 'rgba(75, 192, 192, 0.2)',
          borderColor: 'rgba(75, 192, 192, 1)',
          borderWidth: 1
        }]
      },
      options: {
        scales: {
          y: {
            beginAtZero: true
          }
        }
      }
    });

    let totalAmountChart = document.getElementById('totalAmountChart');
    let labelsTotalAmount = JSON.parse(totalAmountChart.dataset.labels);
    let datasetTotalAmount = JSON.parse(totalAmountChart.dataset.dataset);

    new Chart(totalAmountChart, {
      type: 'line',
      data: {
        labels: labelsTotalAmount,
        datasets: [{
          label: 'Total Amount',
          data: datasetTotalAmount,
          backgroundColor: 'rgba(255, 159, 64, 0.2)',
          borderColor: 'rgba(255, 159, 64, 1)',
          borderWidth: 1
        }]
      },
      options: {
        scales: {
          y: {
            beginAtZero: true
          }
        }
      }
    });

    let averagePaymentDaysChart = document.getElementById('averagePaymentDaysChart');
    let labelsAveragePaymentDays = JSON.parse(averagePaymentDaysChart.dataset.labels);
    let datasetAveragePaymentDays = JSON.parse(averagePaymentDaysChart.dataset.dataset);

    new Chart(averagePaymentDaysChart, {
      type: 'line',
      data: {
        labels: labelsAveragePaymentDays,
        datasets: [{
          label: 'Average Payment Days',
          data: datasetAveragePaymentDays,
          backgroundColor: 'rgba(153, 102, 255, 0.2)',
          borderColor: 'rgba(153, 102, 255, 1)',
          borderWidth: 1
        }]
      },
      options: {
        scales: {
          y: {
            beginAtZero: true
          }
        }
      }
    });

  }

  const selectedLanguage = getCookie('lang') || 'fr';
  $('#language_select').val(selectedLanguage).formSelect();
 
  $('#language_select').on("change", function() {
    console.log("Changement de langue")
    const selectedLanguage = $(this).val();
    console.log(selectedLanguage)
    document.cookie = "lang=" + selectedLanguage + ";path=/";
    location.reload();
  });

  let rotated = false;
  $(".toggle-filters").on("click", function(){
    const icon = $(this).find('.filter-icon'); 
    rotated = !rotated;
    icon.css("transform", rotated ? "rotate(180deg)" : "rotate(0deg)");
    $(".filters-section").toggle();
    initTableHeight();
  });


  function updateFilterCount() {
    let count = 0;
    $(".filters input").each(function() {
      if ($(this).attr("type") === "hidden") {
        return true;
      }

      if ($(this).is(":checkbox")) {
        if ($(this).is(":checked")) {
          count++;
        }
      } else {
        if ($(this).val()) {
          count++;
        }
      }
    });
    $(".toggle-filters span.filters-count").remove();
    $(".toggle-filters").append(count ? `<span class="filters-count">${count}</span>` : "");
  }
  
  $('.remove_service_button').on('click', function(e) {
    $(this).closest('.nested-fields').find('[name$="[_destroy]"]').val('true');
  });

  $('.show_invoice_association').on('click', function(){
    const id = $(this).attr("data-id");
    const currentTransform = $(this).css("transform");
    if(currentTransform === "matrix(-1, 0, 0, -1, 0, 0)"){
      $(this).css("transform", "rotate(0deg)");
      // $(this).closest('tr').css('border-bottom', '1px solid rgba(0,0,0,0.12)')
    } else {
      $(this).css("transform", "rotate(180deg)");
      // $(this).closest('tr').css('border-bottom', 'none')
    }
    $(`.invoice_association[data-id='${id}']`).toggle();
    initTableHeight();
    hideBorderPrevLine();
  })

  // Mettre à jour le compteur de filtres au chargement de la page
  updateFilterCount();
  // Initialiser la heauteur des tableaux au chargement de la page
  initTableHeight();
}

// Appelez initDropdowns() à chaque fois que le contenu de la page est chargé ou mis à jour
document.addEventListener('turbolinks:load', init);
document.addEventListener('DOMContentLoaded', init);