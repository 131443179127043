const bankAccountElements = {
    'fr': [
        {name: 'Ma banque', value: '{ma_banque}'},
        {name: 'Mon IBAN', value: '{mon_iban}'},
        {name: 'Mon BIC', value: '{mon_bic}'},
        {name: 'Ma devise', value: '{ma_devise}'},
    ],
    'en': [
        {name: 'My company bank', value: '{company_bank}'},
        {name: 'My company IBAN', value: '{company_iban}'},
        {name: 'My company BIC', value: '{company_bic}'},
        {name: 'My currency', value: '{company_currency}'},
    ]
};

export default bankAccountElements;