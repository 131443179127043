const customerElements = {
    'fr': [
        {name: 'Nom du client', value: '{nom_du_client}'},
        {name: 'Forme juridique du client', value: '{forme_juridique_du_client}'},
        {name: 'Code SIRET du client', value: '{code_siret_du_client}'},
        {name: 'Code SIREN du client', value: '{code_siren_du_client}'},
        {name: 'N° de TVA du client', value: '{numero_tva_du_client}'},
        {name: 'Code comptable du client', value: '{code_comptable_du_client}'},
        {name: 'Email du client', value: '{email_du_client}'},
        {name: 'Téléphone du client', value: '{telephone_du_client}'},
        {name: 'Fax du client', value: '{fax_du_client}'},
        {name: 'Adresse du client', value: '{adresse_du_client}'},
        {name: 'Compélement d\'adresse du client', value: '{complement_adresse_du_client}'},
        {name: 'Suffixe d\'adresse (CEDEX/BP)', value: '{suffixe_adresse_du_client}'},
        {name: 'Code postal du client', value: '{code_postal_du_client}'},
        {name: 'Ville du client', value: '{ville_du_client}'},
        {name: 'Pays du client', value: '{pays_du_client}'},
        {name: 'Site web du client', value: '{site_web_du_client}'},
        {name: 'Adresse de facturation du client', value: '{facturation_adresse_du_client}'},
        {name: 'Complément d\'adresse de facturation du client', value: '{facturation_complement_adresse_du_client}'},
        {name: 'Ville de facturation du client', value: '{facturation_ville_du_client}'},
        {name: 'Suffixe d\'adresse de facturation du client', value: '{facturation_suffixe_adresse_du_client}'},
        {name: 'Code postal de facturation du client', value: '{facturation_code_postal_du_client}'},
        {name: 'Pays de facturation du client', value: '{facturation_pays_du_client}'},
    ],
    'en': [
        {name: 'Customer company name', value: '{customer_company_name}'},
        {name: 'Customer legal status', value: '{customer_legal_status}'},
        {name: 'Customer SIRET code', value: '{customer_siret_code}'},
        {name: 'Customer SIREN number', value: '{customer_siren}'},
        {name: 'Customer VAT number', value: '{customer_vat_number}'},
        {name: 'Customer billing code', value: '{customer_billing_code}'},
        {name: 'Customer email address', value: '{customer_email}'},
        {name: 'Customer phone number', value: '{customer_phone}'},
        {name: 'Customer fax number', value: '{customer_fax}'},
        {name: 'Customer address', value: '{customer_address}'},
        {name: 'Customer complementary address', value: '{customer_complementary_address}'},
        {name: 'Customer city suffix', value: '{customer_city_suf}'},
        {name: 'Customer ZIP code', value: '{customer_zip_code}'},
        {name: 'Customer city', value: '{customer_city}'},
        {name: 'Customer country', value: '{customer_country}'},
        {name: 'Customer website', value: '{customer_website}'},
        {name: 'Customer invoice address', value: '{customer_ia_address}'},
        {name: 'Customer invoice complementary address', value: '{customer_ia_complementary_address}'},
        {name: 'Customer invoice address city suffix', value: '{customer_ia_city_suf}'},
        {name: 'Customer invoice address ZIP code', value: '{customer_ia_zip_code}'},
        {name: 'Customer invoice address city', value: '{customer_ia_city}'},
        {name: 'Customer invoice address country', value: '{customer_ia_country}'},
    ]
};

export default customerElements;