const billingElements = {
    'fr': [
        {name: 'Mon n° de Siret', value: '{mon_siret}'},
        {name: 'Mon n° de Siren', value: '{mon_siren}'},
        {name: 'Mon code NAF', value: '{mon_code_naf}'},
        {name: 'Mon RCS', value: '{mon_rcs}'},
        {name: 'Mon capital social', value: '{mon_capital_social}'},
        {name: 'Mon numéro de TVA', value: '{mon_numero_de_tva}'},
        {name: 'Mon taux de TVA par défaut', value: '{mon_taux_de_tva_par_defaut}'},
        {name: 'Mon type de TVA', value: '{mon_type_de_tva}'},
        {name: 'Mes conditions générales de vente', value: '{mes_cgv}'},
    ],
    'en': [
        {name: 'My Siret code', value: '{company_siret_code}'},
        {name: 'My Siren code', value: '{company_siren_code}'},
        {name: 'My NAF code', value: '{company_naf_code}'},
        {name: 'My RCS', value: '{company_rcs}'},
        {name: 'My company social capital', value: '{company_social_capital}'},
        {name: 'My company VAT number', value: '{company_vat_number}'},
        {name: 'My company VAT default rate', value: '{company_vat_default_rate}'},
        {name: 'My company VAT type', value: '{company_vat_type}'},
        {name: 'My company billing comment', value: '{company_comment_billing}'},
    ]
};

export default billingElements;