const companyElements = {
    'fr': [
        {name: 'Mon nom de société', value: '{mon_nom_de_societe}'},
        {name: 'Ma forme juridique', value: '{ma_forme_juridique}'},
        {name: 'Mon email', value: '{mon_email}'},
        {name: 'Mon téléphone', value: '{mon_telephone}'},
        {name: 'Mon fax', value: '{mon_fax}'},
        {name: 'Mon site internet', value: '{mon_site_web}'},
        {name: 'Mon adresse', value: '{mon_adresse}'},
        {name: 'Mes informations d\'adresse complémentaire', value: '{mon_adresse_complementaire}'},
        {name: 'Mon code postal', value: '{mon_code_postal}'},
        {name: 'Ma ville', value: '{ma_ville}'},
        {name: 'Mon pays', value: '{mon_pays}'},
    ],
    'en': [
        {name: 'My company name', value: '{company_name}'},
        {name: 'My company legal status', value: '{company_legal_status}'},
        {name: 'My company email', value: '{company_email}'},
        {name: 'My company phone', value: '{company_phone}'},
        {name: 'My company fax', value: '{company_fax}'},
        {name: 'My company website', value: '{company_website}'},
        {name: 'My company address', value: '{company_address}'},
        {name: 'My company complementary address', value: '{company_complementary_address}'},
        {name: 'My company zip code', value: '{company_zip_code}'},
        {name: 'My company city', value: '{company_city}'},
        {name: 'My company country', value: '{company_country}'},
    ]
};

export default companyElements;